import { BrowserRouter } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { useModalContext } from 'provider/ModalProvider/ModalProvider';
import UsernameRoute from './UsernameRoute';
import DomainRoute from './DomainRoute';

export const webHostURL = new URL(
  String(process.env.REACT_APP_WEB_HOST ?? 'http://localhost:3000'),
);

const App: FC = () => {
  const { showModal } = useModalContext();
  useEffect(() => {
    const isShowWarningForCreator = localStorage.getItem(
      'isShowWarningForCreator',
    );
    if (isShowWarningForCreator && isShowWarningForCreator === 'true') {
      showModal(<CreatorWarningModal />);
    }
  }, []);

  return (
    <BrowserRouter>
      {window.location.hostname === webHostURL.hostname ? (
        <UsernameRoute />
      ) : (
        <DomainRoute />
      )}
    </BrowserRouter>
  );
};

export default App;

const CreatorWarningModal = () => {
  const { closeModal } = useModalContext();
  return (
    <div className="max-w-md">
      <p>
        You have logged in with your Creator credentials, which will not display
        your content correctly. We recommend to logout and login with a
        different email so you can see the content from a user perspective
      </p>
      <button
        onClick={() => {
          closeModal();
          if (localStorage.getItem('isShowWarningForCreator')) {
            localStorage.setItem('isShowWarningForCreator', 'false');
          }
        }}
        type="button"
        className="mx-auto ttnk-button"
      >
        OK
      </button>
    </div>
  );
};
